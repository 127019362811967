<template>
  <Menu as="div" class="relative group">
    <MenuButton class="-m-1.5 flex items-center">
      <span class="sr-only">Open user menu</span>
      <img v-if="user" class="h-8 w-8 rounded-full bg-darkgray-900 shadow-outline shadow-darkgray-500 object-cover" :src="user?.avatar || icon" alt="" />
      <USkeleton v-else class="h-8 w-8 rounded-full" />
      <span class="hidden lg:flex lg:items-center">
        <div class="ml-4 text-sm font-semibold leading-4 text-gray-100 flex flex-col items-start">
          <span v-if="user" aria-hidden="true">{{ user?.name }}</span>
          <USkeleton v-else class="h-3 w-20" />
          <p v-if="user" class="text-orange-400 text-xs">{{ user?.subscription ? user?.subscription.name : 'Free account' }}</p>
          <USkeleton v-else class="h-3 w-24 mt-1" />
        </div>
        <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-100" aria-hidden="true" />
      </span>
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems class="absolute right-0 z-20 mt-2.5 min-w-32 origin-top-right rounded-md bg-gray-900 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
        <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }" @click.stop.prevent="item.onClick">
          <a :href="item.href" :class="[active ? 'bg-gray-950 text-white' : '', 'block px-3 py-1 text-sm leading-6 text-gray-100 whitespace-nowrap']">{{ item.name }}</a>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon, ChevronDownIcon } from '@heroicons/vue/24/outline';

import icon from '~/assets/img/icon_white.png';

export default {
  components: { Menu, MenuButton, MenuItem, MenuItems, Bars3Icon, XMarkIcon, ChevronDownIcon },
  data() {
    return {
      icon
    }
  },
  computed: {
    userNavigation() {
      const userNavigation = [
        { name: 'Your account', href: '/account/', onClick: () => this.yourAccount() },
        // { name: 'Your profile', href: 'https://mattepaint.com/gallery/account/', onClick: () => navigateTo('https://mattepaint.com/gallery/account/', { external: true }) },
        { name: 'Sign out', href: '/logout/', onClick: () => this.logout() },
      ]
      if (this.user && !this.user.subscription) {
        userNavigation.unshift({ name: 'Purchase Subscription', href: '/plans/', onClick: () => navigateTo('/plans/') })
      }
      return userNavigation;
    }
  },
  methods: {
    yourAccount() {
      navigateTo('/account/')
    },
    async logout() {
      await this.$store.auth.Logout();
      window.location.href = '/';
    }
  }
}
</script>